import { useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { getImageUrl } from "../../utils/helpers";

import Skeleton from "../ui/Skeleton";

export default function ProjectNotesDisplay({
  projectDetails,
  loadingInformation,
  setCanEditNotes,
}) {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  return loadingInformation ? (
    <div className="flex items-center space-x-2">
      <p className="text-xl">Description:</p>
      <Skeleton className="h-5 w-2/3 bg-slate-300" />
    </div>
  ) : (
    <div className="flex items-center space-x-6">
      <p className="text-xl">Description: {projectDetails?.notes}</p>
      <img
        src={getImageUrl("pencil.svg", isDark)}
        alt="Edit button to change project notes"
        className={`w-7 h-7 ${
          loadingInformation ? "cursor-not-allowed" : "cursor-pointer"
        } rounded-lg hover:bg-gray-200 p-1 duration-200`}
        onClick={() => {
          if (!loadingInformation) setCanEditNotes(true);
        }}
      />
    </div>
  );
}
