import { useState } from "react";

import Projects from "../projects/Projects";
import useRetrieveProjects from "../../hooks/useRetrieveProjects";
import CreateNewProjectButton from "./CreateNewProjectButton";
import CreateNewProjectForm from "./CreateNewProjectForm";

export default function ProjectSelector({ loadingInformation }) {
  const [isOpenAddNewProject, setIsOpenAddNewProject] = useState(false);
  const { projects, setProjects } = useRetrieveProjects();

  return (
    !loadingInformation && (
      <section className="flex flex-col space-y-10 w-full">
        <h2 className="text-4xl font-bold text-center">Projects</h2>
        <section className="flex flex-col items-center space-y-8 lg:space-y-12 overflow-auto py-6">
          {projects?.length === 0 && (
            <>
              <h3 className="text-center text-2xl font-bold text-gray-800">
                You don't have any projects yet
              </h3>
              <article className="bg-white shadow-md border rounded-2xl w-full xl:w-1/2 p-8 space-y-6 text-center relative">
                {isOpenAddNewProject ? (
                  <CreateNewProjectForm
                    closeForm={() => setIsOpenAddNewProject(false)}
                    setProjects={setProjects}
                  />
                ) : (
                  <CreateNewProjectButton
                    openForm={() => setIsOpenAddNewProject(true)}
                  />
                )}
              </article>
            </>
          )}
          {projects?.length > 0 && (
            <Projects
              projects={projects}
              handleClick={setIsOpenAddNewProject}
              isFormOpen={isOpenAddNewProject}
            />
          )}
        </section>
      </section>
    )
  );
}
