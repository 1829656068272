function addZero(num) {
  return num < 10 ? "0" + num : num;
}

export function formatProjectCreationDate(dateString) {
  if (!dateString) return "";
  const date = new Date(dateString);

  return (
    addZero(date.getDate()) +
    "/" +
    addZero(date.getMonth() + 1) +
    "/" +
    date.getFullYear()
  );
}

export function formatProjectUpdateDate(dateString) {
  if (!dateString) return "Never updated";
  const date = new Date(dateString);

  return (
    addZero(date.getHours()) +
    ":" +
    addZero(date.getMinutes()) +
    " " +
    addZero(date.getDate()) +
    "/" +
    addZero(date.getMonth() + 1) +
    "/" +
    date.getFullYear()
  );
}

export function formatDate(dateString) {
  if (!dateString) return "";
  const date = new Date(dateString);
  // format date as: mm-dd-yyyy
  const newDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return newDate.replace(/\//g, "-");
}

const IMAGE_URL = "https://storage.googleapis.com/gpp4-bucket/rsc/rsc/";
export function getImageUrl(name, isLight = false) {
  return `${IMAGE_URL}${!isLight ? "dark-" : "light-"}${name}`;
}
