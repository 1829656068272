import { useState, useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { API_URL } from "../../../utils/api";
import { getUser } from "../../../utils/user";
import { getImageUrl } from "../../../utils/helpers";
import toast from "react-hot-toast";

export default function DownloadResultsButton({ results }) {
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const user = getUser();
      const response = await fetch(`${API_URL}/brenda/download_brenda_csv`, {
        method: "POST",
        body: JSON.stringify(results),
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) throw response;

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "results.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      error?.data?.detail
        ? toast.error(`Error: ${error.data.detail}`)
        : toast.error(`An unexpected error has occured. Try again later`);
    }
    setIsLoading(false);
  };

  return (
    <button
      className={`bg-blue-500 text-white py-2 px-4 rounded-lg flex mx-auto justify-center w-full hover:bg-blue-600 duration-200 ${
        isLoading ? "opacity-50 cursor-not-allowed" : ""
      }`}
      onClick={handleClick}
      disabled={isLoading}>
      {isLoading ? (
        <img
          src="https://storage.googleapis.com/gpp4-bucket/rsc/rsc/dark-loader.svg"
          alt="loader"
          className="h-6 w-6"
        />
      ) : (
        <div className="flex items-center space-x-4">
          <img
            src={getImageUrl("download.svg", isDark)}
            alt="Download results as CSV"
            className="h-6"
          />
          <span>Download results as a CSV file</span>
        </div>
      )}
    </button>
  );
}
