import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import Spacer from "./Spacer";

export default function Footer() {
  return (
    <footer className=" bg-gray-300 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border-gray-100 flex flex-col py-6 text-center justify-center px-3 md:justify-between md:items-center md:!px-8 md:py-8 md:flex-row space-y-6 md:space-y-0 dark:bg-gray-900 dark:text-gray-300 min-h-[24dvh]">
      <div className="flex flex-col space-y-2 md:text-left">
        <h4 className="text-lg font-semibold">Gene Paradox Platform © 2024</h4>
        <a
          href="https://www.geneparadox.com/privacy_policy.html"
          target="_blank"
          rel="noreferrer"
          className="hover:cursor-pointer hover:font-normal hover:text-blue-500 transition duration-200">
          Privacy Policy
        </a>
        <a
          href="https://geneparadox.com/Terms_and_conditions.html"
          target="_blank"
          rel="noreferrer"
          className="hover:cursor-pointer hover:font-normal hover:text-blue-500 transition duration-200">
          Terms and Conditions
        </a>
        <a
          href="mailto:info@geneparadox.com"
          target="_blank"
          rel="noreferrer"
          className="hover:cursor-pointer hover:font-normal hover:text-blue-500 transition duration-200">
          Contact Us
        </a>
      </div>

      <div className="md:hidden w-full">
        <Spacer />
      </div>

      <div className="flex flex-col justify-center items-center md:justify-normal md:items-end space-y-4">
        <img
          className="max-w-[150px]"
          src="https://storage.googleapis.com/gpp4-bucket/rsc/rsc/google-cloud-startup.png"
          alt="Platform powered by Google for Startups"
        />
        <div className="flex flex-row justify-end">
          <a
            href="https://www.linkedin.com/company/geneparadox/mycompany/"
            target="_blank"
            rel="noreferrer">
            <FontAwesomeIcon
              className="h-8 w-8 cursor-pointer hover:scale-125 duration-200 hover:text-blue-500"
              icon={faLinkedin}
            />
          </a>
        </div>
      </div>
    </footer>
  );
}
