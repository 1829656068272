import { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import useValidateModule from "../hooks/useValidateModule";
import GoBackButton from "../components/project_details/ui/GoBackButton";
import ModuleSelectorButton from "../components/project_details/ui/ModuleSelectorButton";
import ProjectSelector from "../components/projects/ProjectSelector";
import Project from "../components/project_details/ProjectDetails";
import Module1 from "../components/Module1";
import Module2 from "../components/Module2";
import Module3 from "../components/Module3";
import Module4 from "../components/Module4";
import Module5 from "../components/Module5";
import LoadingScreen from "../components/project_details/ui/LoadingScreen";

export default function Projects() {
  const { projectId, moduleId, navigate } = useValidateModule();
  const { state } = useContext(GlobalContext);
  const loadingInformation = state.loadingInformation;

  return (
    <main
      className={`w-full flex flex-col mx-auto px-3 md:!px-8 py-6 space-y-6 min-h-[64dvh] ${
        loadingInformation && "relative"
      }`}>
      {loadingInformation && (moduleId || !projectId) && <LoadingScreen />}
      {!projectId && (
        <ProjectSelector loadingInformation={loadingInformation} />
      )}
      {projectId && (
        <>
          <section className="flex flex-col space-y-2 w-full justify-center items-center md:flex-row md:space-y-0 md:justify-between">
            <GoBackButton
              navigate={navigate}
              loadingInformation={loadingInformation}
            />
            <ModuleSelectorButton
              projectId={projectId}
              moduleId={moduleId}
              loadingInformation={loadingInformation}
            />
          </section>
          {!moduleId && <Project />}
          {moduleId === "1" && <Module1 />}
          {moduleId === "2" && <Module2 />}
          {moduleId === "3" && <Module3 />}
          {moduleId === "4" && <Module4 />}
          {moduleId === "5" && <Module5 />}
        </>
      )}
    </main>
  );
}
