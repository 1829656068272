import { useNavigate } from "react-router-dom";

export default function ModuleButton({
  content = "",
  disabled = false,
  path = "/",
  isSelected = false,
  hideModuleSelector = () => {},
}) {
  const navigate = useNavigate();

  return (
    <button
      className={`w-full border flex flex-col items-center justify-center space-y-2 bg-white ${
        !isSelected && !disabled && "hover:!bg-blue-600 hover:text-white"
      } duration-200 p-2 rounded-lg ${
        isSelected &&
        !disabled &&
        "!bg-blue-500 text-white hover:!cursor-not-allowed"
      } ${disabled && "!bg-gray-300 text-gray-500 !cursor-not-allowed"}`}
      disabled={disabled || isSelected}
      onClick={() => {
        navigate(path);
        hideModuleSelector();
      }}>
      {content}
    </button>
  );
}
