import { getImageUrl } from "../../utils/helpers";
import { useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function Header() {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  return (
    <header className="w-full flex flex-col justify-center items-center space-y-8 mb-8">
      <img src={getImageUrl("logo.png", isDark)} className="h-20" alt="logo" />
      <p className="text-center font-semibold text-2xl">
        Please login to your account
      </p>
    </header>
  );
}
