export default function ResultItem({
  result,
  handleClick,
  isSelected = false,
}) {
  return (
    <button
      onClick={handleClick}
      aria-label="Result item"
      disabled={isSelected}
      className={`flex flex-col space-y-4 w-full border rounded-lg py-2 px-4 cursor-pointer enabled:hover:bg-gray-200 disabled:cursor-not-allowed duration-200 ${
        isSelected && "bg-gray-200"
      }`}>
      <h5 className="font-semibold text-lg text-left">{result.title}</h5>
      <div className="flex justify-between">
        <p>{result.identifier}</p>
        <p>{result.length}</p>
      </div>
    </button>
  );
}
