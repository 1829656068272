import CreateNewProjectForm from "./CreateNewProjectForm";
import Project from "./Project";
import { addProjectFormClasses } from "../../helpers/projects";

export default function Projects({
  projects,
  handleClick,
  isFormOpen = false,
}) {
  return (
    <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      <article
        onClick={() => !isFormOpen && handleClick(true)}
        className={addProjectFormClasses(isFormOpen)}>
        {isFormOpen ? (
          <CreateNewProjectForm closeForm={() => handleClick(false)} />
        ) : (
          <>
            <p className="text-[7rem] text-white">+</p>
            <p className="text-lg text-white">Create New Project</p>
          </>
        )}
      </article>
      {projects.map((project) => (
        <Project key={project.project_id} project={project} />
      ))}
    </section>
  );
}
