import { useState } from "react";
import useRetrieveProjectInfo from "../../hooks/useRetrieveProjectInfo";
import CreatedAt from "./ui/CreatedAt";
import ProjectNotesForm from "./ProjectNotesForm";
import ProjectNotesDisplay from "./ProjectNotesDisplay";
import UpdatedAt from "./ui/UpdatedAt";
import Name from "./ui/Name";
import DeleteProject from "./ui/DeleteProject";
import Spacer from "../ui/Spacer";

export default function ProjectDetails() {
  const { projectDetails, projectNotes, setProjectNotes, loadingInformation } =
    useRetrieveProjectInfo();

  const [canEditNotes, setCanEditNotes] = useState(false);
  // TODO: change how notes input is set
  return (
    <section className="flex flex-col space-y-6 w-full">
      <main className="space-y-6">
        <Name
          projectDetails={projectDetails}
          loadingInformation={loadingInformation}
        />
        {canEditNotes ? (
          <ProjectNotesForm
            projectNotes={projectNotes}
            setProjectNotes={setProjectNotes}
            setCanEditNotes={setCanEditNotes}
            projectDetails={projectDetails}
          />
        ) : (
          <ProjectNotesDisplay
            projectDetails={projectDetails}
            loadingInformation={loadingInformation}
            setCanEditNotes={setCanEditNotes}
          />
        )}
        <CreatedAt
          projectDetails={projectDetails}
          loadingInformation={loadingInformation}
        />
        <UpdatedAt
          projectDetails={projectDetails}
          loadingInformation={loadingInformation}
        />
      </main>
      <Spacer />
      <DeleteProject
        projectDetails={projectDetails}
        disabled={loadingInformation}
      />
    </section>
  );
}
