import { BrowserRouter as Router } from "react-router-dom";
import NotLoggedInRoutes from "./routes/NotLoggedInRoutes";
import LoggedInRoutes from "./routes/LoggedInRoutes";
import NavBar from "./components/ui/NavBar";
import Footer from "./components/ui/Footer";
import { Toaster } from "react-hot-toast";
import { initAxiosInterceptors } from "./utils/api";
import CookiesNotice from "./components/ui/CookiesNotice";
import useLoadUser from "./hooks/useLoadUser";
import useTheme from "./hooks/useTheme";

initAxiosInterceptors();

export default function App() {
  const {
    userInformation,
    hasAgreed,
    setHasAgreed,
    handleLogout,
    setUserInformation,
  } = useLoadUser();

  useTheme();

  return (
    <>
      <Toaster position="top-center" />
      <Router>
        {userInformation.access_token && userInformation.refresh_token ? (
          <>
            {!hasAgreed && <CookiesNotice setHasAgreed={setHasAgreed} />}
            <NavBar clearUser={handleLogout} />
            <LoggedInRoutes />
            <Footer />
          </>
        ) : (
          <NotLoggedInRoutes setUserInformation={setUserInformation} />
        )}
      </Router>
    </>
  );
}
