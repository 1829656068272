import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { getImageUrl } from "../../../utils/helpers";

export default function GoBackButton({ navigate, loadingInformation }) {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  return (
    <button
      disabled={loadingInformation}
      className="flex space-x-2 duration-200 border rounded-lg py-2 px-4 enabled:hover:bg-gray-100 md:w-fit w-full justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed"
      onClick={() => navigate("/projects")}>
      <img
        src={getImageUrl("go-back.svg", isDark)}
        className="h-6 w-6"
        alt="Go back svg icon"
      />
      <span>Go back to projects</span>
    </button>
  );
}
