import { showDeleteModal } from "../../../utils/project-info/helpers";
import { useNavigate } from "react-router-dom";

export default function DeleteProject({ projectDetails, disabled = false }) {
  const navigate = useNavigate();
  return (
    <div className="flex justify-end">
      <button
        disabled={disabled}
        className="bg-red-500 text-white rounded-lg py-2 px-4 enabled:hover:bg-red-600 duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={() => showDeleteModal(projectDetails?.project_id, navigate)}>
        Delete project
      </button>
    </div>
  );
}
