export default function InputField({ value, error, dispatch, id, type }) {
  const capitalizedId = id.charAt(0).toUpperCase() + id.slice(1);

  return (
    <>
      <label className="font-semibold" htmlFor={id}>
        {capitalizedId}
      </label>
      <input
        type={id}
        id={id}
        className={`${
          error && "!border-red-500"
        } w-full p-2 rounded-lg border border-gray-300 outline-none focus:shadow-2xl transition-all duration-500`}
        placeholder={capitalizedId}
        onChange={({ target }) => dispatch({ type, payload: target.value })}
        value={value}
      />
    </>
  );
}
