import { useEffect, useState, useCallback } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { getUser, deleteUser } from "../utils/user";
import { API_URL } from "../utils/api";

export default function useLoadUser() {
  const [userInformation, setUserInformation] = useState({});
  const [hasAgreed, setHasAgreed] = useState(() => {
    return JSON.parse(localStorage.getItem("hasAgreed")) || false;
  });

  const handleLogout = (message = null) => {
    setUserInformation({});
    deleteUser();
    if (message) {
      toast.error(message);
    }
  };

  const loadUser = useCallback(async (user) => {
    setUserInformation(user);
    try {
      const response = await axios.get(`${API_URL}/users/whoAmI`);
      if (response.status !== 200) throw response;
    } catch (error) {
      if (error?.status === 401) {
        handleLogout("Your session has expired, please login again");
      }
      error?.data?.detail
        ? toast.error(`Error: ${error.data.detail}`)
        : toast.error("Could not retrieve credentials, try again later.");
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("invalid_credentials")) {
      handleLogout(localStorage.getItem("invalid_credentials"));
      localStorage.removeItem("invalid_credentials");
    }
    const user = getUser();
    if (user) loadUser(user);
  }, [loadUser]);

  return {
    userInformation,
    hasAgreed,
    setHasAgreed,
    handleLogout,
    loadUser,
    setUserInformation,
  };
}
