export default function CookiesNotice({ setHasAgreed }) {
  return (
    <section className="flex flex-col bg-gray-100 border-t-2 p-3 md:!py-9 space-y-4 fixed w-screen z-50 shadow-xl bottom-0">
      <p className="text-sm md:text-base text-center">
        Gene Paradox uses cookies to improve your experience in our site.
      </p>
      <p className="text-sm md:text-base text-center">
        To find out more, check our{" "}
        <a
          href="https://www.geneparadox.com/privacy_policy.html"
          target="_blank"
          rel="noreferrer"
          className="hover:cursor-pointer hover:font-normal hover:text-blue-600 text-blue-500 duration-200 hover:underline">
          Privacy Policy
        </a>{" "}
        and our{" "}
        <a
          href="https://geneparadox.com/Terms_and_conditions.html"
          target="_blank"
          rel="noreferrer"
          className="hover:cursor-pointer hover:font-normal hover:text-blue-600 text-blue-500 duration-200 hover:underline">
          Terms and Conditions
        </a>
        .
      </p>
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 w-fit mx-auto duration-200"
        onClick={() => {
          localStorage.setItem("hasAgreed", true);
          setHasAgreed(true);
        }}>
        Got it!
      </button>
    </section>
  );
}
