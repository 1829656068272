import { NavLink, Link, useNavigate } from "react-router-dom";
import { navLinkClasses } from "../../utils/navbar/helpers";
import { getImageUrl } from "../../utils/helpers";

export default function DesktopNavBar({
  setIsOpenMenu,
  setIsOpenModal,
  isOpenModal,
  handleClick,
  handleLogout,
  clearUser,
  isDark,
}) {
  const navigate = useNavigate();

  return (
    <nav className="hidden md:flex items-center py-6 px-8 min-h-[12dvh] border-b-2 border-gray-200 dark:border-gray-700 drop-shadow-lg space-x-10">
      <Link to="/" onClick={handleClick}>
        <img
          src={getImageUrl("logo.png", isDark)}
          alt="Logo"
          className="h-16 hover:cursor-pointer"
        />
      </Link>
      <div className="space-x-4 h-full">
        <NavLink
          onClick={handleClick}
          className={({ isActive }) => navLinkClasses(isActive, isOpenModal)}
          to="/">
          Home
        </NavLink>
        <NavLink
          onClick={handleClick}
          className={({ isActive }) => navLinkClasses(isActive, isOpenModal)}
          to="/projects">
          Projects
        </NavLink>
        <NavLink
          onClick={handleClick}
          className={({ isActive }) => navLinkClasses(isActive, isOpenModal)}
          to="/tools">
          Tools
        </NavLink>
        <span
          onClick={() => {
            setIsOpenMenu(false);
            handleLogout(isOpenModal, setIsOpenModal, clearUser, navigate);
          }}
          className={navLinkClasses(isOpenModal)}>
          Log out
        </span>
      </div>
    </nav>
  );
}
