export default function Input({ id, value, handleChange, placeholder }) {
  return (
    <article className="flex flex-col w-1/2 space-y-2">
      <label className="font-semibold" htmlFor={id}>
        {id.charAt(0).toUpperCase() + id.slice(1)}
      </label>
      <input
        id={id}
        name={id}
        value={value.value}
        onChange={handleChange}
        placeholder={placeholder}
        autoComplete="off"
        type="text"
        className="rounded-lg bg-gray-100 p-2 w-full duration-100 focus:border-gray-400 focus:border outline-none"
      />
      {value.error && (
        <span className="text-red-500 text-sm">{value.error}</span>
      )}
    </article>
  );
}
