import { useState, useContext } from "react";
import toast from "react-hot-toast";
import { handleLogout } from "../../utils/navbar/helpers";
import { GlobalContext } from "../../contexts/GlobalContext";
import MobileNavBar from "../navbar/MobileNavBar";
import DesktopNavBar from "../navbar/DesktopNavBar";

export default function NavBar({ clearUser }) {
  const { state } = useContext(GlobalContext);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const isDark = state.darkMode;

  function handleClick() {
    setIsOpenMenu(false);
    setIsOpenModal(false);
    toast.dismiss();
  }

  return (
    <header className="bg-gray-300 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 border-gray-100 dark:border-gray-800 dark:bg-gray-900 dark:text-white">
      <MobileNavBar
        isDark={isDark}
        isOpenMenu={isOpenMenu}
        isOpenModal={isOpenModal}
        setIsOpenMenu={setIsOpenMenu}
        setIsOpenModal={setIsOpenModal}
        handleClick={handleClick}
        handleLogout={handleLogout}
        clearUser={clearUser}
      />
      <DesktopNavBar
        isDark={isDark}
        isOpenModal={isOpenModal}
        setIsOpenMenu={setIsOpenMenu}
        setIsOpenModal={setIsOpenModal}
        handleClick={handleClick}
        handleLogout={handleLogout}
        clearUser={clearUser}
      />
    </header>
  );
}
