import { useState } from "react";
import SequenceResult from "./SequenceResult";
import { useScrollIntoView } from "../../../hooks/useScrollIntoView";
import TableHead from "./sequence_table/TableHead";
import PaginationControls from "./sequence_table/PaginationControls";

export default function SequenceTable({
  results,
  handleDomainIdChange,
  canSelect = false,
}) {
  const ref = useScrollIntoView();

  // Table pagination
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 10;
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = results.slice(indexOfFirstResult, indexOfLastResult);
  const totalPages = Math.ceil(results.length / resultsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    results.length > 0 && (
      <article className="space-y-4">
        <div ref={ref} />
        <h4 className="font-semibold text-2xl">Sequence Alignment Results</h4>
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
        <div className="w-full overflow-scroll">
          <table className="w-full text-center">
            <TableHead />
            <tbody>
              {currentResults.map((result) => (
                <SequenceResult
                  key={result.alignment_hit_id}
                  result={result}
                  canSelect={canSelect}
                  handleDomainIdChange={
                    canSelect ? handleDomainIdChange : () => {}
                  }
                />
              ))}
            </tbody>
          </table>
        </div>
      </article>
    )
  );
}
