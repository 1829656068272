export default function CreateNewProjectButton({ openForm }) {
  return (
    <>
      <p className="text-lg text-gray-600">Start by creating a new project</p>
      <button
        className="py-3 px-8 bg-blue-500 hover:bg-blue-600 text-white rounded-full shadow-md hover:shadow-lg duration-200"
        onClick={openForm}>
        Create New Project
      </button>
    </>
  );
}
