export default function Overview() {
  return (
    <aside className="hidden md:flex w-1/2 xl:w-1/3 bg-gradient-to-br from-custom-blue to-custom-pink rounded-r-2xl flex-col justify-center space-y-8 px-8 shadow-2xl">
      <h4 className="text-4xl font-bold text-center text-white">
        We are more than just a company
      </h4>
      <p className="text-xl font-light text-justify text-white">
        At the epicenter of bioinformatic innovation, we extend a warm welcome
        to The Gene Paradox Platform. Here, science meets technology to unlock
        the secrets of the genetic code and open new frontiers in
        biotechnological research.
      </p>
    </aside>
  );
}
