export default function PasswordErrorContainer({ error, children }) {
  return (
    <div
      className={`flex items-center ${
        error ? "justify-between" : "justify-end"
      }`}>
      {children}
    </div>
  );
}
