export default function TableHead() {
  return (
    <thead>
      <tr className="bg-gray-200">
        <th className="border-2 border-gray-400 py-2 px-1">Alignment Hit ID</th>
        <th className="border-2 border-gray-400 py-2 px-1">Alignment Length</th>
        <th className="border-2 border-gray-400 py-2 px-1">Alignment Title</th>
        <th className="border-2 border-gray-400 py-2 px-1">HSP Expect</th>
        <th className="border-2 border-gray-400 py-2 px-1">Identity</th>
        <th className="border-2 border-gray-400 py-2 px-1">Query Coverage</th>
      </tr>
    </thead>
  );
}
