import { useEffect, useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { API_URL } from "../utils/api";
import axios from "axios";
import { GlobalContext } from "../contexts/GlobalContext";

export default function useValidateProject() {
  const { dispatch } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("project");

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    const getProject = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await axios.post(
          `${API_URL}/users/get_proyect_info?project_id=${id}`
        );
        if (response.status !== 200) throw response;
        else if (response.data?.error === "Project does not belong to user.") {
          response.data.detail = "This project does not exist.";
          navigate("/projects");
          throw response;
        }
      } catch (error) {
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while retrieving project, please try again."
            );
        navigate(`/projects`);
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };
    getProject();
  }, [navigate, id, dispatch]);
}
