import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import modules from "../assets/modules.json";
import toast from "react-hot-toast";

function moduleIsDisabled(moduleId) {
  const module = modules.find((module) => module.short_name === moduleId);
  return !module ? true : module.disabled;
}

export default function useValidateModule() {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("project");
  const moduleId = searchParams.get("module");

  const navigate = useNavigate();

  useEffect(() => {
    if (!moduleId) return;
    if (moduleIsDisabled(moduleId)) {
      navigate(`/projects?project=${projectId}`);
      toast.error("This module is not available for this project.");
    }
  }, [navigate, moduleId, projectId]);

  return { moduleId, projectId, navigate };
}
