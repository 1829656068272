export default function InterproLink({ id }) {
  return (
    <p className="border rounded-lg px-2 text-center py-6 text-gray-500 cursor-default">
      Analyze and verify functional domains from the protein accessing{" "}
      <a
        target="_blank"
        rel="noreferrer"
        className="underline text-blue-500 hover:text-blue-600"
        href={`https://www.ebi.ac.uk/interpro/result/InterProScan/${id}`}>
        InterPro data link
      </a>
    </p>
  );
}
