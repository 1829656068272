import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../utils/api";
import toast from "react-hot-toast";
import SearchButton from "./ui/SearchButton";
import SearchForm from "./ui/SearchForm";
import SearchInput from "./ui/SearchInput";

function handleError(error) {
  toast.dismiss();
  error?.data?.detail
    ? toast.error(`Error: ${error.data.detail}`)
    : toast.error("An error occurred while searching for homologus sequences");
}

export default function SequenceAlignment({
  geneFasta,
  handleFastaChange,
  handleAlignmentResultsChange,
  children,
}) {
  const [microorganism, setMicroorganism] = useState({ value: "", error: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [blastId, setBlastId] = useState("");

  useEffect(() => {
    if (!blastId || finished) return;
    if (!isLoading) setIsLoading(true);
    const interval = setInterval(() => {
      try {
        axios
          .post(`${API_URL}/seq_alignment/blast/status?rid=${blastId}`)
          .then((response) => {
            if (response?.data === "FINISHED") {
              clearInterval(interval);
              setIsLoading(false);
              try {
                setIsLoading(true);
                axios
                  .post(`${API_URL}/seq_alignment/blast/get?rid=${blastId}`)
                  .then((response) => {
                    if (response.data) {
                      setIsLoading(false);
                      toast.dismiss();
                      handleAlignmentResultsChange({
                        clickedIdentifier: "",
                        results: response.data || [],
                      });
                      clearInterval(interval);
                      setFinished(true);
                    } else {
                      setIsLoading(false);
                      toast.dismiss();
                      toast.error(
                        "An unexpected error has occurred, please try again"
                      );
                      clearInterval(interval);
                      setFinished(true);
                    }
                    toast.dismiss();
                  });
              } catch (error) {
                setIsLoading(false);
                handleError(error);
              }
            }
          });
      } catch (error) {
        setIsLoading(false);
        setBlastId("");
        handleError(error);
        clearInterval(interval);
      }
    }, 7500);
    return () => {
      clearInterval(interval);
    };
  }, [blastId, isLoading, handleAlignmentResultsChange, finished]);

  async function handleSequenceAligment(e) {
    toast.dismiss();
    e.preventDefault();
    let isValid = true;
    if (geneFasta.value.trim() === "") {
      handleFastaChange({
        ...geneFasta,
        error: "FASTA Sequence must not be empty",
      });
      isValid = false;
    }
    if (microorganism.value.trim() === "") {
      setMicroorganism({
        ...microorganism,
        error: "Microorganism must not be empty",
      });
      isValid = false;
    }
    if (!isValid) return;
    setIsLoading(true);
    toast.loading(
      "Searching for homologus sequences\n\nThis process may take a while"
    );
    try {
      const response = await axios.post(`${API_URL}/seq_alignment/blastx/run`, {
        query: geneFasta.value,
        organism: microorganism.value.split("(")[0].trim(),
      });
      if (response.status !== 200) throw response;
      setBlastId(response.data);
      setFinished(false);
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  }

  return (
    <article className="space-y-2">
      <h4 className="font-semibold text-2xl">Homologus Sequence Alignment</h4>

      {/* This is the FastaDisplay component */}
      {children}

      <SearchForm
        labelValue={
          "Enter the name of the microorganism you wish produce the protein of interest"
        }
        identifier={"microorganism"}
        handleSubmit={handleSequenceAligment}
        error={microorganism.error}>
        <SearchInput
          identifier="microorganism"
          value={microorganism.value}
          setValue={setMicroorganism}
          placeholder={"Microorganism Name"}
          disabled={isLoading || !geneFasta.value}
          show={true}
        />
        <SearchButton
          disabled={isLoading || !geneFasta.value}
          isLoading={isLoading}
        />
      </SearchForm>
    </article>
  );
}
