export default function FastaLoader() {
  return (
    <div className="border rounded-lg p-2 w-full duration-100 min-h-44 bg-gray-300 flex justify-center items-center">
      <img
        className="h-10 mx-auto"
        src="https://storage.googleapis.com/gpp4-bucket/rsc/rsc/dark-loader.svg"
        alt="loading svg"
      />
    </div>
  );
}
