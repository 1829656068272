export default function PreviousButton({ currentPage, handlePageChange }) {
  return (
    <button
      className="bg-gray-300 hover:bg-gray-400 text-gray-800 w-1/3 md:w-1/6 py-2 px-4 rounded duration-200 disabled:cursor-not-allowed disabled:hover:bg-gray-300 disabled:opacity-50"
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}>
      Previous
    </button>
  );
}
