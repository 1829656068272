import { useState, useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import { getImageUrl } from "../utils/helpers";
import toast from "react-hot-toast";
import ToolsAside from "../components/ToolsAside";
import codonTable from "../assets/codon_table.json";

const initialState = {
  value: "",
  error: "",
  canClick: false,
  valid: false,
};

function translateToAminoAcids(sequence) {
  const aminoAcids = sequence.map((triplet) => {
    return codonTable[triplet];
  });
  return aminoAcids;
}

export default function NucleotideToAminoacid() {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  const [data, setData] = useState(initialState);

  return (
    <main className="max-w-5xl mx-auto p-8 md:flex md:space-x-6 space-y-6 md:space-y-0 min-h-[64dvh]">
      <ToolsAside id={7} />
      <div className="space-y-10 md:w-3/4">
        <h2 className="text-4xl font-bold">
          Nucleotide to Amino Acid Translator
        </h2>
        <section className="space-y-4">
          <label htmlFor="sequence" className="font-semibold text-2xl">
            1. Insert your Nucleotide Sequence that will be translated to Amino
            Acids
          </label>
          <textarea
            value={data.value}
            onChange={(e) => {
              if (e.target.value.trim() === "") {
                setData(initialState);
                return;
              }
              if (e.target.value.trim() !== "") {
                const data = e.target.value.toUpperCase().split("\n");
                const res = data.filter((t) => !t.match(/[^ATUCG]/g));
                if (res.length > 0)
                  setData({
                    value: res.join("").replace(/T/g, "U"),
                    error: "",
                    canClick: true,
                  });
              } else
                setData((prev) => ({
                  ...prev,
                  error: "",
                  canClick: false,
                  valid: false,
                }));
            }}
            name="sequence"
            id="sequence"
            cols="30"
            rows="10"
            className="w-full p-4 border-2 rounded-lg"
            placeholder=">Sequence"
          />
          <p className="text-sm">
            * T nucleotides will be changed to U nucleotides
          </p>
          {data.error && <p className="text-red-500 text-lg">{data.error}</p>}
        </section>
        <button
          disabled={!data.canClick}
          onClick={() => {
            const valueNoSpaces = data.value.replace(/\s/g, "");
            if (valueNoSpaces.length % 3 !== 0) {
              setData((prev) => ({
                ...prev,
                error: `The sequence must be a multiple of 3, you're missing ${
                  3 - (valueNoSpaces.length % 3)
                } characters`,
                canClick: false,
                valid: false,
              }));
              return;
            } else {
              setData((prev) => ({
                ...prev,
                error: "",
                canClick: true,
                valid: true,
              }));
            }
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white py-3 w-full rounded-lg duration-200 disabled:hover:bg-blue-500 disabled:opacity-70 disabled:cursor-not-allowed">
          Translate
        </button>

        {data.valid && (
          <div className="w-full border-2 p-4 rounded-xl shadow-xl space-y-6">
            <div className="flex items-center justify-between">
              <h3 className="text-3xl font-bold">Amino Acids from sequence</h3>
              <img
                className="h-9 hover:cursor-pointer duration-200 hover:bg-gray-200 rounded-md p-1 active:bg-gray-300"
                src={getImageUrl("copy.svg", isDark)}
                alt="clipboard icon to copy cut sequence"
                onClick={() => {
                  navigator.clipboard.writeText(
                    translateToAminoAcids(data.value.match(/.{1,3}/g)).join("")
                  );
                  toast.success("Sequence copied to clipboard.");
                }}
              />
            </div>
            <p className="break-words">
              {translateToAminoAcids(data.value.match(/.{1,3}/g)).join("")}
            </p>
          </div>
        )}
      </div>
    </main>
  );
}
