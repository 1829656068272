import { useEffect } from "react";

export default function LoadingScreen({ content }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="h-full w-full bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-50 border border-gray-100 flex flex-col justify-center items-center space-y-2 absolute top-0 left-0 z-50">
      <img
        src="https://storage.googleapis.com/gpp4-bucket/rsc/rsc/dark-loader.svg"
        alt="loader"
        className="h-16 w-16"
      />
      {content && <p className="font-semibold text-lg text-white">{content}</p>}
    </div>
  );
}
