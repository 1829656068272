import { NavLink, Link, useNavigate } from "react-router-dom";
import { navLinkClasses } from "../../utils/navbar/helpers";
import Spacer from "../ui/Spacer";
import { getImageUrl } from "../../utils/helpers";

export default function MobileNavBar({
  isOpenMenu,
  isOpenModal,
  setIsOpenMenu,
  setIsOpenModal,
  handleClick,
  handleLogout,
  clearUser,
  isDark,
}) {
  const navigate = useNavigate();

  return (
    <>
      <nav className="flex justify-between items-center py-6 px-3 md:hidden min-h-[12dvh] border-b-2 border-gray-200 drop-shadow-lg sticky top-0 dark:border-gray-700">
        <Link
          className="text-3xl text-white font-semibold"
          to="/"
          onClick={() => setIsOpenMenu(false)}>
          <img
            src={getImageUrl("logo.png", isDark)}
            alt="Logo"
            className="h-16 hover:cursor-pointer"
          />
        </Link>
        <button
          disabled={isOpenModal}
          className="disabled:cursor-not-allowed cursor-pointer disabled:opacity-50 transition-opacity duration-200"
          onClick={() => setIsOpenMenu((prev) => !prev)}>
          <img
            src={getImageUrl(isOpenMenu ? "close.svg" : "menu.svg", isDark)}
            alt={
              isOpenMenu
                ? "Closing icon for responsive menu"
                : "Menu icon for responsive menu"
            }
            className="h-10 p-1 rounded-md border bg-gray-50 dark:!bg-gray-800 shadow-md enabled:hover:shadow-lg enabled:hover:!bg-gray-200 duration-200 dark:!border-gray-600"
          />
        </button>
      </nav>
      {isOpenMenu && (
        <div className="md:hidden space-y-3 py-4 px-6 flex flex-col absolute bg-gray-300 dark:bg-gray-800 w-full text-center rounded-b-xl shadow-md">
          <NavLink
            onClick={handleClick}
            className={({ isActive }) =>
              navLinkClasses(isActive, isOpenModal) + " mx-auto"
            }
            to="/">
            Home
          </NavLink>
          <NavLink
            onClick={handleClick}
            className={({ isActive }) =>
              navLinkClasses(isActive, isOpenModal) + " mx-auto"
            }
            to="/projects">
            Projects
          </NavLink>
          <NavLink
            onClick={handleClick}
            className={({ isActive }) =>
              navLinkClasses(isActive, isOpenModal) + " mx-auto"
            }
            to="/tools">
            Tools
          </NavLink>
          <Spacer />
          <span
            onClick={() => {
              setIsOpenMenu(false);
              handleLogout(isOpenModal, setIsOpenModal, clearUser, navigate);
            }}
            className={navLinkClasses(isOpenModal) + " mx-auto"}>
            Log out
          </span>
        </div>
      )}
    </>
  );
}
