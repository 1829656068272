import { useNavigate } from "react-router-dom";
import {
  formatProjectCreationDate,
  formatProjectUpdateDate,
} from "../../utils/helpers";

export default function Project({ project }) {
  const navigate = useNavigate();
  const { name, notes, createdAt, updatedAt, project_id: id } = project;

  return (
    <article
      className="bg-white border border-gray-200 rounded-2xl shadow-md p-6 hover:shadow-xl cursor-pointer flex flex-col justify-between space-y-4  duration-300"
      onClick={() => navigate(`/projects?project=${id}`)}>
      <h2 className="text-2xl font-bold text-blue-600 hover:text-blue-700 duration-200">
        {name}
      </h2>
      <p className="text-gray-700 truncate">
        <span className="font-semibold">Notes:</span> {notes}
      </p>
      <div className="text-sm text-gray-500 space-y-1">
        <p>
          <span className="text-black font-semibold">Created on:</span>{" "}
          {formatProjectCreationDate(createdAt)}
        </p>
        <p>
          <strong className="text-black font-semibold">Last updated:</strong>{" "}
          {formatProjectUpdateDate(updatedAt)}
        </p>
      </div>
    </article>
  );
}
