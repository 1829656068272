export default function Header({ ref }) {
  return (
    <header className="block">
      <div ref={ref} />
      <div className="block space-y-6">
        <h3 className="text-center text-balance font-bold text-3xl">
          Module 1 Search of Microorganism for Protein Production
        </h3>
        <p className="bg-gray-200 text-balance p-3 rounded-md shadow-sm border-2 border-gray-300 text-center w-fit mx-auto">
          The module conducts a comprehensive search, utilizing techniques such
          as BLASTX and protein domain analysis to identify if the candidate
          microorganism meet the specified criteria.
        </p>
      </div>
    </header>
  );
}
