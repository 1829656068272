import { useState } from "react";
import ToolsAside from "../components/ToolsAside";

export default function NucleotideCounter() {
  const [sequence, setSequence] = useState("");

  return (
    <main className="max-w-5xl mx-auto p-8 md:flex md:space-x-6 space-y-6 md:space-y-0 min-h-[64dvh]">
      <ToolsAside id={2} />
      <div className="space-y-10 md:w-3/4">
        <h2 className="text-4xl font-bold">Nucleotide Counter</h2>
        <section className="space-y-4">
          <label htmlFor="fasta" className="font-semibold text-2xl">
            1. Insert your FASTA Sequence
          </label>
          <textarea
            value={sequence}
            onChange={(e) => {
              if (e.target.value.trim() !== "") {
                const data = e.target.value.toUpperCase().split("\n");
                const res = data.filter((t) => !t.match(/[^ATCG]/g));
                if (res.length > 0) setSequence(res.join(""));
              } else if (e.target.value.trim() === "") setSequence("");
              else setSequence((prev) => prev);
            }}
            name="fasta"
            id="fasta"
            cols="30"
            rows="10"
            className="w-full p-4 border-2 rounded-lg"
            placeholder=">Sequence"
          />
        </section>
        <section className="flex flex-col space-y-4">
          <label htmlFor="length" className="font-semibold text-2xl">
            Total Number of Molecules
          </label>
          <input
            className="border-2 py-2 px-4 rounded-lg "
            type="text"
            name="length"
            id="length"
            readOnly
            placeholder="# of Molecules"
            value={sequence.length || ""}
          />
        </section>
        <section className="space-y-4">
          <h3 className="font-semibold text-2xl">Nucleotides</h3>
          <div className="grid grid-cols-4 gap-4">
            <input
              className="col-span-2 py-2 md:col-span-1 text-center border-2 rounded-lg"
              readOnly
              type="text"
              name="A"
              id="A"
              placeholder="# of A's"
              value={
                sequence.match(/A/g) ? "A = " + sequence.match(/A/g).length : ""
              }
            />
            <input
              className="col-span-2 py-2 md:col-span-1 text-center border-2 rounded-lg"
              readOnly
              type="text"
              name="T"
              id="T"
              placeholder="# of T's"
              value={
                sequence.match(/T/g) ? "T = " + sequence.match(/T/g).length : ""
              }
            />
            <input
              className="col-span-2 py-2 md:col-span-1 text-center border-2 rounded-lg"
              readOnly
              type="text"
              name="C"
              id="C"
              placeholder="# of C's"
              value={
                sequence.match(/C/g) ? "C = " + sequence.match(/C/g).length : ""
              }
            />
            <input
              className="col-span-2 py-2 md:col-span-1 text-center border-2 rounded-lg"
              readOnly
              type="text"
              name="G"
              id="G"
              placeholder="# of G's"
              value={
                sequence.match(/G/g) ? "G = " + sequence.match(/G/g).length : ""
              }
            />
          </div>
        </section>
        <section className="flex flex-col space-y-4">
          <label htmlFor="gc" className="font-semibold text-2xl">
            GC Content
          </label>
          <input
            className="border-2 py-2 px-4 rounded-lg"
            type="text"
            readOnly
            name="gc"
            id="gc"
            placeholder="GC Content"
            value={
              sequence.match(/[GC]/g)
                ? `${(
                    (sequence.match(/[GC]/g).length / sequence.length) *
                    100
                  ).toFixed(2)}%`
                : ""
            }
          />
        </section>
      </div>
    </main>
  );
}
