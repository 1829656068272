export default function SubmitButton({ isLoading }) {
  return (
    <button
      type="submit"
      className="bg-blue-500 text-white px-4 py-2 rounded-xl hover:bg-blue-600 duration-200 w-1/3 disabled:opacity-50 disabled:cursor-not-allowed"
      disabled={isLoading}>
      {isLoading ? (
        <img
          src="https://storage.googleapis.com/gpp4-bucket/rsc/rsc/dark-loader.svg"
          alt="loader"
          className="h-6 w-6 mx-auto"
        />
      ) : (
        "Submit"
      )}
    </button>
  );
}
