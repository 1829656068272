import toolsData from "../assets/tools.json";
import { Link } from "react-router-dom";

export default function Tools() {
  toolsData.sort((a, b) => a.name.localeCompare(b.name));
  const tools = [
    ...toolsData.filter((tool) => tool.canUse),
    ...toolsData.filter((tool) => !tool.canUse),
  ];

  return (
    <main className="space-y-6 max-w-6xl py-6 mx-auto min-h-[64dvh] px-3">
      <h2 className="text-4xl font-bold text-center">Toolbox</h2>
      <ul className="grid grid-cols-2 gap-8 ">
        {tools.map((tool) => (
          <li
            key={tool.id}
            className="flex flex-col space-y-6 col-span-2 md:col-span-1 justify-between border-2 p-4 rounded-xl shadow-xl">
            <h3 className="text-3xl font-bold">{tool.name}</h3>
            <p>{tool.description}</p>
            <Link
              to={tool.canUse ? tool.link : "/tools"}
              className={`bg-blue-500 rounded-lg px-6 py-2 text-white text-center hover:bg-blue-600 duration-200 ${
                !tool.canUse &&
                "opacity-50 cursor-not-allowed hover:bg-blue-500"
              }`}>
              {tool.canUse ? "Try it!" : "Available soon"}
            </Link>
          </li>
        ))}
      </ul>
    </main>
  );
}
