import useGenbankProtein from "../../hooks/useGenbankProtein";
import FastaDisplay from "./ui/FastaDisplay";
import Input from "./ui/sequence_cutter/Input";

export default function SequenceCutter({ value, domainFasta, setDomainFasta, setCutSequence }) {
  const { isLoading, begin, setBegin, end, setEnd } = useGenbankProtein(value, setDomainFasta);

  function handleClick() {
    const new_begin = parseInt(begin.value);
    const new_end = parseInt(end.value);
    const sequence = domainFasta.value;
    setCutSequence(sequence.slice(new_begin - 1, new_end));
  }

  function handleBeginChange({ target }) {
    const value = parseInt(target.value);
    //  validaciones para que el valor sea un número, que no sea menor que 1 y que no sea mayor que el largo de la secuencia
    if (isNaN(value)) 
      setBegin({ value: "", error: "Please insert a valid number." });
    else if (value < 1) 
      setBegin({ value, error: "Please insert a number greater than 0." });
    else if (value > domainFasta.value.length) 
      setBegin({ value, error: `Please insert a number less than ${domainFasta.value.length}.` });
    else 
      setBegin({ value, error: "" });
  }

  function handleEndChange({target}){
    const value = parseInt(target.value);
    if (isNaN(value)) 
      setEnd({ value: "", error: "Please insert a valid number." });
    else if (value < 1) 
      setEnd({ value, error: "Please insert a number greater than 0." });
    else if (value > domainFasta.value.length) 
      setEnd({ value, error: `Please insert a number less than ${domainFasta.value.length}.` });
    else if (value < begin) 
      setEnd({ value, error: "Please insert a number greater than the begin value." });
    else 
      setEnd({ value, error: "" });
  }

  return (
    <>
      <h5 className="font-semibold text-xl">Sequence Cutter</h5>
      <FastaDisplay
        isLoading={isLoading}
        fastaSequence={domainFasta.value}
        fastaError={domainFasta.error}
        handleFastaChange={setDomainFasta}
        identifier="domainFasta"
      />
      <section className="flex space-x-10">
        <Input id="begin" value={begin} handleChange={handleBeginChange} placeholder={`Min value: 1`} />
        <Input id="end" value={end} handleChange={handleEndChange} placeholder={`Max value: ${domainFasta.value?.length}`} />
      </section>
      <button
        onClick={handleClick}
        disabled={!domainFasta.value || isLoading || begin.error || end.error || !begin.value || !end.value}
        className="bg-blue-500 text-white rounded-lg hover:bg-blue-600 duration-200 w-full py-2 cursor-pointer disabled:cursor-not-allowed disabled:hover:bg-blue-500 disabled:opacity-50">
        Cut Sequence
      </button>
    </>
  );
}