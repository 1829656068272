import { useScrollIntoView } from "../hooks/useScrollIntoView";
import useValidateProject from "../hooks/useValidateProject";
import MoleculeViewer from "./module4/MoleculeViewer";

export default function Module4() {
  useValidateProject();
  const ref = useScrollIntoView();

  return (
    <section className="flex flex-col space-y-10 w-full">
      <header className="block">
        <div ref={ref} />
        <div className="block space-y-6">
          <h3 className="text-center text-balance font-bold text-3xl">
            Module 4 Molecule Viewer
          </h3>
        </div>
      </header>
      <MoleculeViewer />
    </section>
  );
}
