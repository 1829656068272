export default function ErrorMessage({ error }) {
  return (
    <p
      className={`text-sm text-red-500 ${
        error ? "opacity-100" : "opacity-0"
      } transition-opacity duration-500`}>
      {error}
    </p>
  );
}
