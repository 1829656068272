// import { useState } from "react";
// import { PDF_API_URL } from "../utils/api";
import useValidateProject from "../hooks/useValidateProject";
import { useSearchParams } from "react-router-dom";
import Portfolios from "./module5/Portfolios";

export default function Module5() {
  useValidateProject();
  const [searchParams] = useSearchParams();
  const portfolioId = searchParams.get("portfolio");

  return (
    <section className="flex flex-col space-y-10 w-full">
      {!portfolioId && <Portfolios />}
    </section>
  );
}
