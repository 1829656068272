import { useState } from "react";
import { useScrollIntoView } from "../hooks/useScrollIntoView";
import useValidateProject from "../hooks/useValidateProject";
import { API_URL } from "../utils/api";
import axios from "axios";
import toast from "react-hot-toast";
import SearchForm from "./module3/ui/SearchForm";
import SearchInput from "./module3/ui/SearchInput";
import SearchButton from "./module3/ui/SearchButton";
import PaginatedTable from "./ui/PaginatedTable";
import OrganismInformation from "./module3/ui/OrganismInformation";

export default function Module3() {
  useValidateProject();
  const ref = useScrollIntoView();
  const [enzyme, setEnzyme] = useState({
    id: -1,
    value: "",
    error: "",
  });
  const [results, setResults] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();
    setIsLoading(true);
    const enzymeId = parseInt(enzyme.value.split(" - ")[0]) || null;
    if (enzyme.value.trim() === "" || !enzymeId) {
      setEnzyme({ ...enzyme, error: "Please enter a valid enzyme name" });
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.get(
        `${API_URL}/brenda/V2/get_organisms?rn_id=${enzymeId}`
      );
      if (response.status !== 200) throw response;
      setResults(response.data);
    } catch (error) {
      error?.data?.detail
        ? toast.error(`Error: ${error.data.detail}`)
        : toast.error("An error occurred while searching for the enzyme.");
    }
    setIsLoading(false);
  }

  return (
    <section className="flex flex-col space-y-10 w-full">
      <header className="block">
        <div ref={ref} />
        <div className="block space-y-6">
          <h3 className="text-center text-balance font-bold text-3xl">
            Module 3 Characteristics for the production of a target enzyme
          </h3>
        </div>
      </header>

      <SearchForm
        labelValue={"Enzyme name"}
        identifier={"enzyme"}
        handleSubmit={handleSubmit}
        error={enzyme.error}>
        <SearchInput
          identifier={"enzyme"}
          placeholder={"Target enzyme"}
          value={enzyme.value}
          setValue={setEnzyme}
          disabled={isLoading}
          show={true}
          double={false}
        />
        {enzyme.error && <p className="text-red-500">{enzyme.error}</p>}
        <SearchButton disabled={isLoading} isLoading={isLoading}></SearchButton>
      </SearchForm>

      {results?.results?.length > 0 && (
        <>
          <OrganismInformation results={results} />
          <PaginatedTable items={results?.results} />
        </>
      )}
    </section>
  );
}
