import { useState } from "react";
import { useScrollIntoView } from "../hooks/useScrollIntoView";
import useValidateProject from "../hooks/useValidateProject";
import GeneSearch from "./module1/GeneSearch";
import FastaSearch from "./module1/FastaSearch";
import SequenceTable from "./module1/ui/SequenceTable";
import DomainSearch from "./module1/DomainSearch";
import ProteinAlignment from "./module1/ProteinAlignment";
import NoSimilarity from "./module1/NoSimilarity";
import Header from "./module1/ui/Header";

// TODO: agregar tooltips de pregunta a los títulos de cada sección para que el usuario sepa qué es lo que tiene que hacer en cada paso

export default function Module1() {
  useValidateProject();
  const ref = useScrollIntoView();

  const [geneResults, setGeneResults] = useState({
    clikedIdentifier: "",
    results: [],
  });

  const [alignmentResults, setAlignmentResults] = useState({
    clikedIdentifier: "",
    results: [],
  });

  const [domainSearchId, setDomainSearchId] = useState({
    value: "",
    error: "",
  });

  const [domainFasta, setDomainFasta] = useState({
    value: "",
    error: "",
  });

  const [cutSequence, setCutSequence] = useState("");
  const [proteinAlignmentResults, setProteinAlignmentResults] = useState([]);

  return (
    <section className="flex flex-col space-y-10 w-full">
      <Header ref={ref} />

      <GeneSearch handleGeneResultsChange={setGeneResults} />

      <FastaSearch
        geneResults={geneResults}
        handleGeneResultsChange={setGeneResults}
        handleAlignmentResultsChange={setAlignmentResults}
        // Sequence alignment is nested in FastaSearch component
      />

      {alignmentResults.results[0] === "No significant similarity found." ? (
        <NoSimilarity />
      ) : (
        alignmentResults.results.length > 0 && (
          <SequenceTable
            results={alignmentResults.results}
            canSelect={true}
            handleDomainIdChange={setDomainSearchId}
          />
        )
      )}

      {domainSearchId.value && (
        <DomainSearch
          domainFasta={domainFasta}
          setCutSequence={setCutSequence}
          setDomainFasta={setDomainFasta}
          domainSearchId={domainSearchId}
        />
      )}

      {cutSequence && (
        <ProteinAlignment
          cutSequence={cutSequence}
          setProteinAlignmentResults={setProteinAlignmentResults}
        />
      )}

      {proteinAlignmentResults[0] === "No significant similarity found." ? (
        <NoSimilarity />
      ) : (
        proteinAlignmentResults.length > 0 && (
          <SequenceTable
            results={proteinAlignmentResults}
            canSelect={false}
            handleDomainIdChange={() => {}}
          />
        )
      )}
    </section>
  );
}
