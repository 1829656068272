import toast from "react-hot-toast";

export function navLinkClasses(isActive, isOpenModal) {
  return isActive && !isOpenModal
    ? "font-semibold uppercase duration-200 bg-[#3091c3] px-4 py-2 rounded-full text-white dark:!text-gray-300 cursor-not-allowed w-1/3 md:w-auto"
    : "font-semibold uppercase duration-200 hover:bg-gray-200 dark:hover:bg-gray-700 px-4 py-2 hover:rounded-full cursor-pointer hover:shadow-md w-1/3 md:w-auto dark:!text-gray-300";
}

export function handleLogout(isOpenModal, setIsOpenModal, clearUser, navigate) {
  if (isOpenModal) return;
  document.body.style.overflow = "hidden";
  toast.dismiss();
  setIsOpenModal(true);
  toast.loading(
    <div className="flex flex-col space-y-4 text-center px-8">
      <span className="text-lg">
        Are you sure you want to <br />
        <strong>log out</strong>?
      </span>
      <div className="w-full flex justify-evenly">
        <button
          onClick={() => {
            toast.dismiss();
            setIsOpenModal(false);
            document.body.style.overflow = "auto";
            setTimeout(() => {
              navigate("/");
              clearUser();
            }, 500);
          }}
          className="border-2 hover:bg-red-600 p-2 w-1/3 duration-200 hover:text-white rounded-lg">
          Yes
        </button>
        <button
          onClick={() => {
            toast.dismiss();
            setIsOpenModal(false);
            document.body.style.overflow = "auto";
          }}
          className="bg-blue-500 hover:bg-blue-600 p-2 w-1/3 duration-200 text-white rounded-lg">
          No
        </button>
      </div>
    </div>,
    { icon: null }
  );
}
