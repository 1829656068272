import { useState, useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import ModuleSelector from "../../ModuleSelector";
import { getImageUrl } from "../../../utils/helpers";

export default function ModuleSelectorButton({
  projectId,
  moduleId,
  loadingInformation,
}) {
  const [isOpenModuleSelector, setIsOpenModuleSelector] = useState(false);
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  return (
    <>
      <div className="flex flex-col space-y-2 relative md:w-fit w-full">
        <button
          className="flex space-x-2 duration-200 border rounded-lg py-2 px-4 hover:bg-gray-100 md:w-fit w-full justify-center items-center"
          onClick={() => setIsOpenModuleSelector(!isOpenModuleSelector)}>
          <img
            src={getImageUrl(
              isOpenModuleSelector ? "collapse.svg" : "expand.svg",
              isDark
            )}
            className="h-6 w-6"
            alt={isOpenModuleSelector ? "Collapse" : "Expand"}
          />
          <span>{isOpenModuleSelector ? "Hide modules" : "Show modules"}</span>
        </button>
        {isOpenModuleSelector && (
          <ModuleSelector
            hideModuleSelector={() => setIsOpenModuleSelector(false)}
            projectId={projectId}
            selectedModule={moduleId}
            loadingInformation={loadingInformation}
          />
        )}
      </div>
    </>
  );
}
