import { Link } from "react-router-dom";

export default function Footer({ loading }) {
  return (
    <div className="flex flex-col justify-center items-center space-y-4">
      <button
        className="bg-[#423cde] py-3 px-8 rounded-xl text-lg text-white enabled:hover:bg-blue-800 duration-200 disabled:cursor-not-allowed disabled:opacity-70"
        type="submit"
        disabled={loading}>
        Log in
      </button>
      <Link className="text-sm group text-black duration-200" to="/sign_up">
        Don't have an account?{" "}
        <span className="text-[#423DD9] group-hover:underline">Sign up</span>
      </Link>
    </div>
  );
}
