import { Route, Routes } from "react-router-dom";
import Home from "../views/Home";
import Projects from "../views/Projects";
import Tools from "../views/Tools";

import SequenceCutter from "../views/SequenceCutter";
import NucleotideCounter from "../views/NucleotideCounter";
import AminoacidCounter from "../views/AminoacidCounter";
import NucleotideToAminoacid from "../views/NucleotideToAminoacid";
import FastaConverter from "../views/FastaConverter";
import GRASOrganism from "../views/GRASOrganism";

export default function LoginRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Home />} />
      <Route path="/tools" element={<Tools />} />
      <Route path="/tools/sequence_cutter" element={<SequenceCutter />} />
      <Route path="/tools/nucleotide_counter" element={<NucleotideCounter />} />
      <Route path="/tools/aminoacid_counter" element={<AminoacidCounter />} />
      <Route path="/tools/fasta_converter" element={<FastaConverter />} />
      <Route path="/tools/gras_organism_list" element={<GRASOrganism />} />
      <Route path="/tools/nucleotide_translator" element={<NucleotideToAminoacid />} />
      <Route path="/projects" element={<Projects />} />
    </Routes>
  );
}
