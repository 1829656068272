import modules from "../assets/modules.json";
import ModuleButton from "./ModuleButton";

export default function ModuleSelector({
  projectId,
  selectedModule,
  loadingInformation,
  hideModuleSelector,
}) {
  return (
    <aside className="bg-gray-100 py-4 px-3 md:!px-4 rounded-xl flex flex-col items-center w-full md:w-[300%] space-y-4 h-fit border md:absolute md:top-10 md:right-0 md:z-50">
      <h3 className="text-3xl font-bold">Modules</h3>
      <section className="w-full space-y-4">
        <ModuleButton
          hideModuleSelector={hideModuleSelector}
          content="Project Details"
          path={`?project=${projectId}`}
          disabled={loadingInformation}
          isSelected={!selectedModule}
        />
        {modules.map(
          (module) =>
            !module.disabled && (
              <ModuleButton
                hideModuleSelector={hideModuleSelector}
                key={module.short_name}
                content={module.description}
                disabled={loadingInformation}
                isSelected={module.short_name === selectedModule}
                path={`?project=${projectId}&module=${encodeURIComponent(
                  module.short_name
                )}`}
              />
            )
        )}
      </section>
    </aside>
  );
}
