import { useState, useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import { getImageUrl } from "../utils/helpers";
import toast from "react-hot-toast";
import ToolsAside from "../components/ToolsAside";

const availableWidths = [60, 90, 120, 150];

export default function FastaConverter() {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  const [title, setTitle] = useState(">");
  const [sequence, setSequence] = useState("");
  const [width, setWidth] = useState("");
  const [convertedSequence, setConvertedSequence] = useState("");

  return (
    <main className="max-w-5xl mx-auto p-8 md:flex md:space-x-6 space-y-6 md:space-y-0 min-h-[64dvh]">
      <ToolsAside id={5} />
      <div className="space-y-10 md:w-3/4">
        <h2 className="text-4xl font-bold">FASTA Converter</h2>
        <section className="space-y-4">
          <label htmlFor="title" className="font-semibold text-2xl">
            1. Insert a title for your sequence
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => {
              e.target.value.trim() === ""
                ? setTitle(">")
                : setTitle(e.target.value);
            }}
            name="title"
            id="title"
            className="border-2 py-2 px-2 rounded-lg w-full"
            placeholder=">Title"
          />
        </section>
        <section className="space-y-4">
          <label htmlFor="sequence" className="font-semibold text-2xl">
            2. Insert your sequence
          </label>
          <textarea
            value={sequence}
            onChange={(e) => {
              setSequence(e.target.value.trim().toUpperCase());
            }}
            name="sequence"
            id="sequence"
            className="border-2 py-2 px-2 rounded-lg w-full min-h-32 mono-font"
            placeholder="Sequence"
            rows={5}
            cols={30}
          />
        </section>
        <section className="space-y-4">
          <label htmlFor="width" className="font-semibold text-2xl">
            3. Select the width of the sequence
          </label>
          <select
            value={width}
            onChange={(e) => {
              setWidth(e.target.value);
            }}
            name="width"
            id="width"
            className="border-2 py-2 px-2 rounded-lg w-full">
            <option value="">Select width</option>
            {availableWidths.map((w) => (
              <option key={w} value={w}>
                {w}
              </option>
            ))}
          </select>
        </section>
        <button
          disabled={
            title.trim() === ">" || sequence.trim() === "" || width === ""
          }
          onClick={() => {
            if (title.trim() === ">" || sequence.trim() === "" || width === "")
              toast.error("Please fill all the fields");
            else {
              const sequenceArray = sequence.split("");
              let fasta = `${title}\n`;
              for (let i = 0; i < sequenceArray.length; i += parseInt(width)) {
                fasta +=
                  sequenceArray.slice(i, i + parseInt(width)).join("") + "\n";
              }
              setConvertedSequence(fasta);
            }
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white py-3 w-full rounded-lg duration-200 disabled:hover:bg-blue-500 disabled:opacity-70 disabled:cursor-not-allowed">
          Convert
        </button>

        {convertedSequence && (
          <div className="w-full border-2 p-4 rounded-xl shadow-xl space-y-6">
            <div className="flex items-center justify-between">
              <h3 className="text-3xl font-bold">Converted Sequence</h3>
              <img
                className="h-9 hover:cursor-pointer duration-200 hover:bg-gray-200 rounded-md p-1 active:bg-gray-300"
                src={getImageUrl("copy.svg", isDark)}
                alt="clipboard icon to copy cut sequence"
                onClick={() => {
                  navigator.clipboard.writeText(convertedSequence);
                  toast.success("Sequence copied to clipboard.");
                }}
              />
            </div>
            <p className="mono-font">{convertedSequence}</p>
          </div>
        )}
      </div>
    </main>
  );
}
