import { useState, useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { getImageUrl } from "../../utils/helpers";
import axios from "axios";
import { API_URL } from "../../utils/api";
import toast from "react-hot-toast";

export default function CreateNewProjectForm({ closeForm, setProjects }) {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [notes, setNotes] = useState("");
  const [notesError, setNotesError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateData() {
    let isValid = true;
    setNameError("");
    setNotesError("");
    if (!name) {
      isValid = false;
      setNameError("Project name is required");
    }
    if (!notes) {
      isValid = false;
      setNotesError("Project notes are required");
    }
    return isValid;
  }

  async function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();
    setIsLoading(true);
    if (validateData()) {
      try {
        const createProjectResponse = await axios.post(
          `${API_URL}/users/create_project`,
          { name, notes }
        );

        if (createProjectResponse.status !== 201) throw createProjectResponse;

        closeForm();
        setName("");
        setNotes("");

        const getProjectsResponse = await axios.post(
          `${API_URL}/users/get_user_projects`
        );

        if (getProjectsResponse.status !== 200) throw getProjectsResponse;

        setProjects(getProjectsResponse.data);
      } catch (error) {
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while processing your request, please try again."
            );
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <img
        src={getImageUrl("close.svg", isDark)}
        alt="close"
        className="h-8 w-8 absolute top-4 right-4 cursor-pointer p-1 rounded-full duration-200 hover:bg-gray-200"
        onClick={closeForm}
      />
      <h2 className="text-2xl font-bold">Create New Project</h2>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center w-full space-y-8">
        <div className="w-full flex flex-col items-start space-y-2">
          <label htmlFor="projectName" className="font-semibold">
            * Project Name
          </label>
          <input
            name="projectName"
            id="projectName"
            type="text"
            placeholder="(max: 50 characters)"
            className="w-full p-2 text-black bg-gray-100 rounded-lg"
            onChange={(e) => {
              e.target.value.length > 50
                ? setNameError("Character limit reached")
                : setNameError("");
              setName((prev) =>
                e.target.value.length > 50 ? prev : e.target.value
              );
            }}
            value={name}
          />
          {nameError && <p className="text-red-500">{nameError}</p>}
        </div>
        <div className="w-full flex flex-col items-start space-y-2">
          <label htmlFor="projectNotes" className="font-semibold">
            * Project Notes
          </label>
          <textarea
            name="projectNotes"
            id="projectNotes"
            placeholder="(max: 300 characters)"
            className="w-full p-2 text-black bg-gray-100 rounded-lg min-h-16"
            onChange={(e) => {
              e.target.value.length > 300
                ? setNotesError("Character limit reached")
                : setNotesError("");
              setNotes((prev) =>
                e.target.value.length > 300 ? prev : e.target.value
              );
            }}
            value={notes}
          />
          {notesError && <p className="text-red-500">{notesError}</p>}
        </div>

        <div className="space-y-2 w-full">
          <button
            className={`bg-blue-500 text-white py-2 px-4 rounded-full flex mx-auto justify-center w-1/2 hover:bg-blue-600 duration-200 ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLoading}>
            {isLoading ? (
              <img
                src="https://storage.googleapis.com/gpp4-bucket/rsc/rsc/dark-loader.svg"
                alt="loader"
                className="h-6 w-6"
              />
            ) : (
              "Create Project"
            )}
          </button>
          <p>* Required field</p>
        </div>
      </form>
    </>
  );
}
