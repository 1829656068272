import { useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function Spacer() {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  return (
    <div
      className={`border-1 ${
        isDark ? "border-gray-300" : "border-gray-600"
      } rounded-full`}></div>
  );
}
