import { useEffect, useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";

const useTheme = () => {
  const { dispatch } = useContext(GlobalContext);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const userPrefersDark = mediaQuery.matches;
    if (userPrefersDark) {
      dispatch({ type: "TOGGLE_THEME", payload: true });
    }

    const handleChange = (e) => {
      dispatch({ type: "TOGGLE_THEME", payload: e.matches });
    };

    mediaQuery.addEventListener("change", handleChange);

    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, [dispatch]);
};

export default useTheme;
