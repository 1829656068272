import { useReducer } from "react";
import { loginReducer, initialState } from "../reducers/LoginReducer";
import { Link, useNavigate } from "react-router-dom";
import { API_URL, initAxiosInterceptors } from "../utils/api";
import { setUser } from "../utils/user";
import { toast } from "react-hot-toast";
import ErrorMessage from "../components/login/ErrorMessage";
import Overview from "../components/login/Overview";
import Header from "../components/login/Header";
import InputField from "../components/login/InputField";
import PasswordErrorContainer from "../components/login/PasswordErrorContainer";
import InputContainer from "../components/ui/InputContainer";
import Footer from "../components/login/Footer";

export default function Login({ setUserInformation }) {
  const [state, dispatch] = useReducer(loginReducer, initialState);
  const { email, emailError, password, passwordError, loading } = state;
  const navigate = useNavigate();

  function validateData() {
    let isValid = true;
    dispatch({ type: "RESET_ERRORS" });
    if (password.trim() === "") {
      dispatch({
        type: "SET_PASSWORD_ERROR",
        payload: "Password must not be empty",
      });
      isValid = false;
    }
    if (email.trim() === "") {
      dispatch({ type: "SET_EMAIL_ERROR", payload: "Email must not be empty" });
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      dispatch({ type: "SET_EMAIL_ERROR", payload: "Invalid email format" });
      isValid = false;
    }
    return isValid;
  }

  async function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();
    if (validateData()) {
      dispatch({ type: "SET_LOADING", payload: true });
      toast.loading("Logging in...");
      try {
        const response = await fetch(`${API_URL}/users/login`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password }),
          timeout: 30000,
        });

        if (response.status !== 200) throw response;

        const data = await response.json();

        const userInformation = {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          user: data.user,
        };

        setUser(userInformation);
        setUserInformation(userInformation);
        initAxiosInterceptors();
        toast.dismiss();
        navigate("/");
      } catch (error) {
        const data = await error.json();
        toast.dismiss();
        data?.detail
          ? toast.error(`${data.detail}`)
          : toast.error("An error occurred while logging in");
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    }
  }

  return (
    <main className="bg-gray-100 min-h-screen flex items-center justify-center">
      <section className="flex justify-center px-6 w-full">
        <article className="bg-white px-6 py-8 w-full shadow-2xl md:w-1/2 xl:w-1/3 rounded-2xl md:rounded-r-none">
          <Header />
          <form onSubmit={handleSubmit} className="space-y-10">
            <InputContainer>
              <InputField
                value={email}
                error={emailError}
                dispatch={dispatch}
                id="email"
                type="SET_EMAIL"
              />
              <ErrorMessage error={emailError} />
            </InputContainer>
            <InputContainer>
              <InputField
                value={password}
                error={passwordError}
                dispatch={dispatch}
                id="password"
                type="SET_PASSWORD"
              />
              <PasswordErrorContainer>
                <ErrorMessage error={passwordError} />
                <Link
                  className="text-sm hover:underline text-[#423DD9]"
                  to="/forgot_password">
                  Forgot your password?
                </Link>
              </PasswordErrorContainer>
            </InputContainer>

            <Footer loading={loading} />
          </form>
        </article>
        <Overview />
      </section>
    </main>
  );
}
